
import { defineComponent, ref, Ref } from 'vue';
import breadCrumb from '@/components/common/bread-crumb';
import { distributorBaseRouter, distributorSubRouter } from '@/router';
import { distributor, office, accountType } from '@/data';
import { list } from '@/components/common/list';
import { getOfficeInfoDevice } from './util';

const navHeaders = [{ label: WordList.PersonuserInfo }];
const basePath = `/${distributorBaseRouter}/${distributorSubRouter.user}?type=officeUser`;
const infoKey: Array <{
    label: string;
    key: keyof accountType.OfficeUserInfo;
}> = [{
    key: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    key: 'SipAccount',
    label: WordList.ProperAllTextSIPNumber
}, {
    key: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    key: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    key: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    key: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    key: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    key: 'ExpireTime',
    label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.NavInHtmlMenuApp})`
}];
export default defineComponent({
    components: {
        breadCrumb,
        list
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const {
            deviceHeaders,
            devRequestData
        } = getOfficeInfoDevice(props.id);
        // 请求办公用户详情，参数用户ID
        const info: Ref< accountType.OfficeUserInfo > = ref({
            Name: '',
            SipAccount: '',
            Email: '',
            MobileNumber: '',
            Install: '',
            Phone: '',
            CreateTime: '',
            ExpireTime: ''
        });
        info.value = JSON.parse(localStorage.getItem('officeInfo')!);

        return {
            navHeaders,
            basePath,
            distributor,
            info,
            infoKey,
            deviceHeaders,
            devRequestData
        };
    }
});
